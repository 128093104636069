<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  let { data } = this.formOptions;
                  if (this.currNode.type == '1001') {
                    data["parentId"] = this.currNode.id;
                    data["organizationId"] = this.currNode.parentId;
                    data["communityId"] = this.currNode.communityId;
                    this.$refs.xGrid.insertEvent();
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
          ],
          export: true,
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "name",
            title: "支出名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入支出名称"
              }
            }
          },
          {
            field: "amount",
            title: "支出金额",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入支出金额"
              }
            }
          },
          {
            field: "payerDate",
            title: "支出日期",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择支出日期"
              }
            }
          },
          {
            field: "payerName",
            title: "付款人名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入付款人名称"
              }
            }
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   width: 120,
          //   cellRender: {
          //     name: "$switch",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       openLabel: "启用",
          //       closeLabel: "禁用",
          //       openValue: "1",
          //       closeValue: "0"
          //     },
          //     events: {
          //       change: ({ row }) => {
          //         this.$refs.xGrid.submitEvent(row)
          //       }
          //     }
          //   },
          // },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            }
          }
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 120,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  if (node.type == '1001') {
                    data["parentId"] = node.id;
                    data["communityId"] = node.communityId;
                    data["organizationId"] = node.parentId;
                  } else {
                    this.$message.error('请选择小区')
                  }
                }
              }
            },
          },
          {
            field: "name",
            title: "支出名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入支出名称"
              }
            }
          },
          {
            field: "amount",
            title: "支出金额",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入支出金额"
              }
            }
          },
          {
            field: "payerName",
            title: "付款人名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入付款人名称",
              },
            },
          },
          {
            field: "payerDate",
            title: "支出日期",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择支出日期",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   span: 12,
          //   itemRender: {
          //     name: "$select",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       placeholder: "请选择状态"
          //     }
          //   }
          // },
          {
            field: "remark",
            title: "描述",
            span: 24,
            itemRender: {
              name: "$textarea",
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: "请输入描述内容"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          amount: [{
            required: true,
            message: "请输入支出金额"
          }],
          name: [{
            required: true,
            message: "请输入支出名称"
          }],
          payerName: [{
            required: true,
            message: "请输入付款人名称"
          }],
          payerDate: [{
            required: true,
            message: "请选择支付日期"
          }],
          enabled: [{
            required: true,
            message: "请选择状态"
          }],
        },
        // 新增功能字段初始化
        data: {
          enabled: "1",
          amount: "",
          name: "",
          payerName: "",
          payerDate: ""
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "支出名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入支出名称"
              }
            }
          },
          {
            field: "payerName",
            title: "付款人名称",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入付款人名称",
              }
            },
          },
          {
            field: "payerDate",
            title: "支出日期",
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                placeholder: "请选择支出日期",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "重置"
                  }
                }
              ]
            }
          }
        ],
        data: {
          name: "",
          payerName: "",
          payerDate: "",
        },
      },
    };
  },
  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.searchOptions.data['parentId'] = node.id;
      this.$refs.xGrid.getLists();
    }
  },
};
</script>

<style lang="scss" scoped></style>
